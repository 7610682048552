import Api from "./Api";

export const Organisms = {
    'santeformapro': {
        id: 1,
        name: 'Santé Formapro',
        logo: 'logo_santeformapro.png',
        icon: 'icon_santeformapro.svg',
        colorPrimary: '#E7157B',
        colorPrimaryOpacity: '#E7157B4a',
        favicon: 'favicon_santeformapro.png',
        phone: '04 42 17 90 82',
        mail: 'contact@santeformapro.com',
        tutor_mail: 'tuteur@santeformapro.com',
        twitter: 'https://twitter.com/sante_formapro',
        facebook: 'https://www.facebook.com/santeformaproofficiel',
        linkedin: 'https://www.linkedin.com/in/santé-formapro-80b435153',
        website: 'https://www.santeformapro.com/',
        googleLink: 'https://www.google.com/search?q=santeformapro&oq=santeformapro+&aqs=chrome..69i57j0i512j0i30j69i60l5.4082j0j7&sourceid=chrome&ie=UTF-8#lrd=0x12c9ed6b76f74735:0xc4cd3f36159934e5,3,,,,',
        faq: 'https://www.santeformapro.com/faq/',
        termsConditions: Api.host() + '/asset/pdfs/tc_santeformapro.pdf',
        dataProtection: Api.host() + '/asset/pdfs/dp_santeformapro.pdf'
    },
    'larevuedupraticien-dpc': {
        id: 2,
        name: 'La Revue du Praticien',
        logo: 'logo_revuedupraticiendpc.png',
        icon: 'icon_revuedupraticiendpc.svg',
        colorPrimary: '#69ABAA',
        colorPrimaryOpacity: '#69ABAA4a',
        favicon: 'favicon_revuedupraticiendpc.png',
        phone: '01 55 62 68 48',
        mail: 'dpc@larevuedupraticien.fr',
        tutor_mail: 'formation@larevuedupraticien.fr',
        twitter: 'https://twitter.com/RevuePraticien',
        facebook: 'https://www.facebook.com/larevuedupraticien',
        linkedin: 'https://www.linkedin.com/showcase/la-revue-du-praticien/',
        website: 'https://www.larevuedupraticien-dpc.fr/',
        googleLink: 'https://www.google.com/search?q=la+revue+du+praticien+dpc&ei=yoiBZP_GDJiIkdUPy-uN2Ak&gs_ssp=eJwFwUsKgCAUAEDaBh2gnZvWPv_lEbqFn6cIEVIaHr-ZeaGZMpZHekPLBia7wZAGtfFccO8UqiNZGIiCGycg7NFrkHCulyMPfh1J7KQ-rpVQ8Caxhh_Mfxjo&oq=la+revue+du+p&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAxgBMgUIABCABDILCC4QgAQQxwEQrwEyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDoKCAAQRxDWBBCwAzoKCAAQigUQsAMQQzoLCAAQgAQQsQMQgwE6EQguEIAEELEDEIMBEMcBENEDOhQILhCABBCxAxCDARDHARDRAxDUAjoICC4QgAQQsQM6BwguEIoFEEM6CwguEIoFELEDEIMBOg4IABCABBCxAxCDARDJAzoICAAQigUQkgM6BAgAEAM6CAgAEIAEELEDOggILhCABBDUAjofCC4QgAQQsQMQgwEQxwEQ0QMQlwUQ3AQQ3gQQ4AQYAToFCC4QgAQ6CwguEIAEELEDENQCOhMILhCABBCXBRDcBBDeBBDgBBgBSgQIQRgAUKcGWIMWYNkjaAJwAXgAgAFyiAHECJIBBDEyLjGYAQCgAQHAAQHIAQraAQYIARABGBQ&sclient=gws-wiz-serp#lrd=0x47e67b232ba5e59f:0xee327a30c8db6040,3,,,,',
        faq: 'https://www.larevuedupraticien-dpc.fr/aide',
        termsConditions: Api.host() + '/asset/pdfs/cg_larevuedupraticiendpc.pdf',
        dataProtection: Api.host() + '/asset/pdfs/cg_larevuedupraticiendpc.pdf'
    },
    'timelia': {
        id: 4,
        name: 'Timelia',
        logo: 'logo_timelia.png',
        icon: 'icon_timelia.svg',
        colorPrimary: '#E73E01',
        colorPrimaryOpacity: '#E73E014a',
        favicon: 'favicon_timelia.png',
        phone: '04 66 62 00 29',
        mail: 'contact@timelia.fr',
        tutor_mail: 'contact@timelia.fr',
        twitter: '#',
        facebook: 'https://www.facebook.com/timeliaformation/',
        linkedin: '#',
        website: 'https://timelia.fr/',
        googleLink: 'https://www.google.com/search?q=iifa&ei=IIiBZMmBD4jGkdUPiNuZ6Ak&ved=0ahUKEwjJkfj6l7P_AhUIY6QEHYhtBp0Q4dUDCA8&uact=5&oq=iifa&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCAAQgAQyBQgAEIAEMgUIABCABDILCC4QgAQQxwEQrwEyBQgAEIAEMhYILhCABBCxAxCDARDHARDRAxDUAhAKMg0ILhCABBDHARCvARAKMg0ILhCABBDHARCvARAKMgcIABCABBAKMg0IABCABBCxAxCDARAKOgoIABBHENYEELADOgoIABCKBRCwAxBDOggIABCABBCxAzoLCAAQgAQQsQMQgwE6DgguEIAEELEDEMcBENEDOhEILhCABBCxAxCDARDHARDRAzoLCC4QigUQsQMQgwE6BQguEIAEOgsILhCABBCxAxDUAjoLCC4QgAQQxwEQ0QM6CgguEIAEENQCEAo6DQguEIAEEMcBENEDEAo6BwgAEA0QgAQ6DQguEA0QgAQQxwEQrwE6CQgAEA0QgAQQCkoECEEYAFDlDVimHGDDH2gEcAF4AIABqAGIAeAEkgEDNC4ymAEAoAEBwAEByAEK&sclient=gws-wiz-serp#lrd=0x12b42da97d5cb927:0xd250e069e5388fe4,3,,,,',
        faq: 'https://timelia.fr/mon-parcours-pedagogique/',
        termsConditions: Api.host() + '/asset/pdfs/cg_timelia.pdf',
        dataProtection: Api.host() + '/asset/pdfs/cg_timelia.pdf'
    },
    'gayetmetoisformation': {
        id: 5,
        logo: 'logo_gayetmetois.svg',
        icon: 'icon_gayetmetois.svg',
        colorPrimary: '#e27a8a',
        colorPrimaryOpacity: '#e27a8a54',
        favicon: 'favicon_gayetmetois.png',
        phone: '06 27 37 35 81',
        mail: 'contact@gayetmetoisformation.fr',
        tutor_mail: 'contact@gayetmetoisformation.fr',
        twitter: 'https://twitter.com/Gayetmetois',
        facebook: 'https://www.facebook.com/gayetmetois/',
        linkedin: 'https://www.linkedin.com/company/gayet-metois/',
        website: 'https://gayetmetoisformation.fr/',
        googleLink: '#',
        faq: '#',
        termsConditions: Api.host() + '/asset/pdfs/cg_gayetmetois.pdf',
        dataProtection: Api.host() + '/asset/pdfs/cg_gayetmetois.pdf'
    },
    'patati': {
        id: 6,
        name: 'Patati',
        logo: 'logo_patati.png',
        icon: 'icon_patati.svg',
        colorPrimary: '#E73E01',
        colorPrimaryOpacity: '#E73E014a',
        favicon: 'favicon_patati.png',
        phone: '04 66 62 00 29',
        mail: 'patati@timelia.fr',
        tutor_mail: 'patati@timelia.fr',
        twitter: '#',
        facebook: '#',
        linkedin: '#',
        website: 'https://patati.tv/',
        googleLink: 'https://www.google.com/search?q=patati+tv&oq=patati+&gs_lcrp=EgZjaHJvbWUqDggAEEUYJxg7GIAEGIoFMg4IABBFGCcYOxiABBiKBTIGCAEQRRg7Mg0IAhAuGK8BGMcBGIAEMg0IAxAuGK8BGMcBGIAEMgcIBBAAGIAEMg0IBRAuGK8BGMcBGIAEMg0IBhAuGK8BGMcBGIAEMgcIBxAAGIAEMgcICBAuGIAEMgcICRAAGI8C0gEJMzQwMmowajE1qAIIsAIB&sourceid=chrome&ie=UTF-8',
        faq: '#',
        termsConditions: Api.host() + '/asset/pdfs/cg_timelia.pdf',
        dataProtection: Api.host() + '/asset/pdfs/cg_timelia.pdf'
    }
};
export const OrganismIndex = {
    1: 'santeformapro',
    2: 'larevuedupraticien-dpc',
    4: 'timelia',
    5: 'gayetmetoisformation',
    6: 'patati'
};
