import React from 'react';
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {makeStyles} from "@material-ui/core";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import UseAnimations from "react-useanimations";
import facebook from "react-useanimations/lib/facebook";
import twitter from "react-useanimations/lib/twitter";
import linkedin from "react-useanimations/lib/linkedin";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../../Api";
import {theme} from "../../../App";
import {AuthenticationLogin} from "../../../Action/AuthenticationAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import BrightnessAuto from '@material-ui/icons/BrightnessAuto';
import {LoginPage} from "../../../Action/LoginPageAction";
import {getRoutePathname} from "../../../Config/Route";
import ParticlesComponent from "../../../Component/ParticlesComponent";

function Login(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const isAdmin = props.location.pathname.startsWith('/authentication/admin');

    const [loading, setLoading] = React.useState(false);
    const [isSmallScreen, setSmallScreen] = React.useState(false);

    const [showLogin, setShowLogin] = React.useState(false);
    const [showForgotPassword, setShowForgotPassword] = React.useState(false);
    const [showChangePassword, setShowChangePassword] = React.useState(false);
    const [showFirstConnection, setShowFirstConnection] = React.useState(false);
    const [forcedCenter, setForcedCenter] = React.useState(true);
    const [forcedHide, setForcedHide] = React.useState(false);
    const [checkCode, setCheckCode] = React.useState(true);
    const [user, setUser] = React.useState({});

    // Register
    const register = () => {
        setForcedCenter(true);
        setShowLogin(false);
        setTimeout(() => {
            props.history.push(getRoutePathname('authentication_register'));
        }, 1000)
    }

    // Login
    const [form, setForm] = React.useState({
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Votre adresse email.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required', 'email']
            }
        },
        password: {
            name: 'password',
            label: 'Mot de passe',
            textHelper: 'Votre mot de passe.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        }
    });
    const handler = formHandlerInit(form, setForm);
    const login = () => {
        if (handler.checkError() < 1) {
            handler.setFormLoading(true);
            setLoading(true);

            let data = handler.getData();

            Api.post({
                route: 'authentication_login',
                data: {...data, isAdmin: isAdmin, organism: theme.id}
            }, (response) => {
                if (response.status === 200) {

                    window.dataLayer.push({
                        'event': 'login',
                        'user_email': data.email,
                        'user_RPPS': '',
                        'user_ID': response.data.id
                    })

                    if (response.data.firstConnection) {
                        handler.setFormLoading(false);
                        setShowLogin(false);
                        setShowFirstConnection(true);
                        setLoading(false);
                        setUser(response.data);
                    }
                    else {
                        setForcedHide(true);
                        setTimeout(() => {
                            dispatch(AuthenticationLogin(response.data))
                            dispatch(LoginPage(isAdmin ? 'admin' : 'learner'))
                        }, 1200);
                    }
                }
                else if (response.status === 403) {
                    handler.setFormLoading(false);
                    setLoading(false);

                    handler.setError('email', 'Votre email ou votre mot de passe est incorrect.');
                }
                else {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    // ForgotPassword
    const [formForgotPassword, setFormForgotPassword] = React.useState({
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Votre adresse email.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required', 'email']
            }
        }
    });
    const handlerForgotPassword = formHandlerInit(formForgotPassword, setFormForgotPassword);
    const forgotPassword = () => {
        if (handlerForgotPassword.checkError() < 1) {
            handlerForgotPassword.setFormLoading(true);
            setLoading(true);
            Api.post({
                route: 'authentication_password_reset_request',
                data: {...handlerForgotPassword.getData(), isAdmin: isAdmin, organism: theme.id}
            }, () => {
                handlerForgotPassword.setFormLoading(false);
                setLoading(false);
                setShowLogin(true);
                setShowForgotPassword(false);
                dispatch(
                    SnackbarOpen({
                        text: 'Si votre adresse mail existe dans notre base de données, vous recevrez un lien de récupération de mot de passe dans quelques secondes.',
                        variant: 'info',
                        duration: 20000
                    })
                );
            });
        }
    }

    // ChangePassword
    const [formChangePassword, setFormChangePassword] = React.useState({
        password: {
            name: 'password',
            label: 'Mot de passe',
            textHelper: 'Votre mot de passe.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required'],
                min: 6,
                max: 32
            }
        }
    });
    const handlerChangePassword = formHandlerInit(formChangePassword, setFormChangePassword);
    const changePassword = () => {
        if (handlerChangePassword.checkError() < 1) {
            handlerChangePassword.setFormLoading(true);
            setLoading(true);

            let data = handlerChangePassword.getData();
            data.code = props.match.params.code;

            Api.post({
                route: 'authentication_password_change',
                data: {...data, isAdmin: isAdmin}
            }, () => {
                handlerChangePassword.setFormLoading(false);
                setLoading(false);
                setShowChangePassword(false);
                setShowLogin(true);

                dispatch(
                    SnackbarOpen({
                        text: 'Votre mot de passe a été modifié, vous pouvez dorénavant vous connecter à votre espace Santeformapro.',
                        variant: 'success',
                        duration: 10000
                    })
                );
            });
        }
    }

    // FirstConnection
    const [formFirstConnection, setFormFirstConnection] = React.useState({
        password: {
            name: 'password',
            label: 'Mot de passe',
            textHelper: 'Votre mot de passe.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required'],
                min: 6,
                max: 32
            }
        }
    });
    const handlerFirstConnection = formHandlerInit(formFirstConnection, setFormFirstConnection);
    const firstConnection = () => {
        if (handlerFirstConnection.checkError() < 1) {
            handlerFirstConnection.setFormLoading(true);
            setLoading(true);

            let data = handlerFirstConnection.getData();

            Api.post({
                route: 'authentication_password_first_change',
                data: {...data, isAdmin: isAdmin}
            }, (response) => {
                if (response.status === 200) {
                    setForcedHide(true);
                    setTimeout(() => dispatch(AuthenticationLogin(user)), 1200);
                }
                else {
                    setShowFirstConnection(false);
                    setShowLogin(true);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isSmallScreenTmp = screenReducer.screen === 'XS';
            if (isSmallScreenTmp !== isSmallScreen) setSmallScreen(isSmallScreenTmp);
        }
    }, [screenReducer.screen]);
    React.useEffect(() => {
        setTimeout(() => {
            if (props.match.params.code) {
                setShowChangePassword(true);
                setTimeout(() => setForcedCenter(false), 50);
                Api.post({
                        route: 'authentication_password_check_validity_code',
                        data: {code: props.match.params.code, isAdmin: isAdmin}
                    },
                    (response) => {
                        setTimeout(() => {
                            if (response.status === 200) {
                                setCheckCode(false);
                            }
                            else {
                                setShowChangePassword(false);
                                setShowLogin(true);
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Ce lien de réinitialisation de mot de passe est expiré. Par sécurité, merci de réitérer la procédure.',
                                        variant: 'warning',
                                        duration: 15000
                                    })
                                );
                            }
                        }, 1500);
                    });
            }
            else {
                setShowLogin(true);
                setTimeout(() => setForcedCenter(false), 50);
            }
        }, 50);
    }, []);

    return (
        <div className={classes.content}>

            {!isSmallScreen && <ParticlesComponent loading={loading}/>}

            <ShadowBoxComponent className={`
                ${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox} 
                ${(!forcedHide && showLogin) && classes.shadowBoxShow}
                ${(!forcedCenter && !showLogin) && classes.shadowBoxLoginHideLeft}
            `}>
                <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo}/>

                <div className={`${isSmallScreen ? classes.shadowBoxSmallContent : classes.shadowBoxContent}`}>
                    {
                        isAdmin ?
                            <>
                                <BrightnessAuto style={{height: 80, width: 80, marginBottom: 30}}/>
                            </> :
                            <>
                                <h1 className={classes.title} style={(theme.id === 1) ? {paddingTop: 40} : {}}>Connectez-vous à notre plateforme</h1>
                                {theme.id === 1 && <p className={classes.text}>Vous n'exercez pas en profession libérale ?</p>}
                                {theme.id === 1 && <a href="http://es.santeformapro.com/plateforme" className={classes.link1}>Accédez à la plateforme établissements de santé</a>}
                            </>
                    }
                    <div>
                        <div><TextFieldComponent name={'email'} handler={handler} onSubmit={login} noSpace={true}/></div>
                        <br/>
                        <div><TextFieldComponent name={'password'} handler={handler} onSubmit={login} password noSpace={true}/></div>
                        <br/>
                        <ButtonComponent type="submit" label={'Se connecter'} className={classes.button} onClick={login} disabled={loading}/>
                        {!isAdmin && <ButtonComponent style={{marginTop: 10, background: theme.colorPrimary}} type="submit" label={'S\'inscrire'} className={classes.button} onClick={register} disabled={loading}/>}

                        <p onClick={() => {
                            setShowLogin(false);
                            setShowForgotPassword(true);
                        }} className={classes.link2}>Mot de passe oublié ?</p>
                    </div>
                </div>

                <div className={classes.network}>
                    <UseAnimations animation={facebook} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.facebook, "_blank")}/>
                    <UseAnimations animation={twitter} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.twitter, "_blank")}/>
                    <UseAnimations animation={linkedin} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.linkedin, "_blank")}/>
                </div>

            </ShadowBoxComponent>

            <ShadowBoxComponent className={`
                ${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox} 
                ${(!forcedHide && showForgotPassword) && classes.shadowBoxShow}
                ${(!forcedCenter && !showForgotPassword) && classes.shadowBoxForgotPasswordHideRight}
            `}>
                <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo}/>

                <div className={`${isSmallScreen ? classes.shadowBoxSmallContent : classes.shadowBoxContent}`}>
                    <h1 className={classes.title}>Mot de passe oublié ?</h1>
                    <p className={classes.text}>Ne vous inquiétez pas, nous pouvons vous aider !</p>
                    <p className={classes.text}>En demandant une réinitialisation, un lien vous sera envoyé sur votre boîte mail afin de le changer.</p>
                    <br/>

                    <div><TextFieldComponent name={'email'} handler={handlerForgotPassword} noSpace={true}/></div>
                    <br/>
                    <ButtonComponent label={'Demande de réinitialisation'} className={classes.button} onClick={forgotPassword} disabled={loading}/>

                    <p onClick={() => {
                        setShowLogin(true);
                        setShowForgotPassword(false);
                    }} className={classes.link2}>Annuler</p>
                </div>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={`
                ${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox} 
                ${(!forcedHide && showChangePassword) && classes.shadowBoxShow}
                ${(!forcedCenter && !showChangePassword) && classes.shadowBoxChangePasswordHideRight}
            `}>
                <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo}/>

                <div className={`${isSmallScreen ? classes.shadowBoxSmallContent : classes.shadowBoxContent}`}>
                    <h1 className={classes.title}>Changer mon mot de passe</h1>
                    <p className={classes.text}>Veuillez saisir votre nouveau mot de passe.</p>
                    <br/>

                    <div><TextFieldComponent name={'password'} handler={handlerChangePassword} password disabled={checkCode} noSpace={true}/></div>
                    <br/>
                    <ButtonComponent label={'Réinitialiser mon mot de passe'} className={classes.button} onClick={changePassword} disabled={loading || checkCode}/>

                    <p onClick={() => {
                        setShowChangePassword(false);
                        setShowLogin(true);
                    }} className={classes.link2}>Annuler</p>

                </div>
                <div className={classes.loaderSecurity}>
                    {checkCode ? <><CircularProgress color="secondary" size={12}/> Contrôle de sécurité en cours...</> : <span style={{color: 'green'}}>Demande sécurisée</span>}
                </div>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={`
                ${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox} 
                ${(!forcedHide && showFirstConnection) && classes.shadowBoxShow}
                ${(!forcedCenter && !showFirstConnection) && classes.shadowBoxFirstConnectionHideRight}
            `}>
                <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo}/>

                <div className={`${isSmallScreen ? classes.shadowBoxSmallContent : classes.shadowBoxContent}`}>
                    <h1 className={classes.title}>Bonjour et bienvenue</h1>
                    <p className={classes.text}>Pour votre première connexion, il est essentiel de personnaliser votre mot de passe.</p>
                    <br/>

                    <div><TextFieldComponent name={'password'} handler={handlerFirstConnection} password onSubmit={firstConnection} noSpace={true}/></div>
                    <br/>
                    <ButtonComponent label={'Initialiser mon mot de passe'} className={classes.button} onClick={firstConnection} disabled={loading}/>
                </div>
            </ShadowBoxComponent>
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    },
    logo: {
        width: '80%',
        margin: 'auto'
    },
    shadowBox: {
        padding: 25,
        height: 520,
        width: 380,
        left: 'calc(50vw - 190px)',
        top: 'calc(50vh - 260px)',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        transition: 'all 700ms',
        transitionProperty: 'opacity, left',
        opacity: 0
    },
    shadowBoxSmall: {
        padding: 25,
        height: '100vh',
        width: '100vw',
        margin: 'auto',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        transition: 'all 700ms',
        transitionProperty: 'opacity, left',
        opacity: 0
    },
    shadowBoxShow: {
        opacity: 1,
    },
    shadowBoxLoginHideLeft: {
        left: -380,
    },
    shadowBoxForgotPasswordHideRight: {
        left: 'calc(100vw + 380px)',
    },
    shadowBoxForgotPasswordHideLeft: {
        left: -380,
    },
    shadowBoxCodeHide: {
        left: 'calc(100vw + 380px)',
    },
    shadowBoxChangePasswordHideRight: {
        left: 'calc(100vw + 380px)',
    },
    shadowBoxFirstConnectionHideRight: {
        left: 'calc(100vw + 380px)',
    },
    shadowBoxContent: {
        marginTop: '50%',
        transform: 'translateY(calc(-58% + 25px))',
    },
    shadowBoxSmallContent: {
        marginTop: '50vh',
        transform: 'translateY(calc(-35vh + 25px))',
    },
    title: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 500,
        margin: '0 0 10px 0'
    },
    text: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 12,
        margin: '0 0 5px 0',
        display: 'block'
    },
    link1: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 12,
        margin: '0 0 27px 0',
        display: 'block',
        cursor: 'pointer'
    },
    link2: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 12,
        margin: '18px 0 0 0',
        display: 'block',
        cursor: 'pointer'
    },
    button: {
        width: '100%'
    },
    network: {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
        width: '100%',
        left: 0
    },
    loaderSecurity: {
        position: 'absolute',
        bottom: 10,
        fontSize: 11,
        textAlign: 'center',
        width: '85%'
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    }
});

export default Login;
