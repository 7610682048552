import React from 'react';
import {Fade, LinearProgress as LinearProgressMUI, makeStyles} from "@material-ui/core";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import MediaLinkComponent from "../../../../Component/MediaLinkComponent";
import {theme} from "../../../../App";
import {useSelector} from "react-redux";
import Api from "../../../../Api";

function CardFormation(props) {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [sizeView, setSizeView] = React.useState('');
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        setSizeView(screenReducer.screen);
    }, [screenReducer.screen]);

    return (
        <Fade in={props.show} {...{timeout: 500}}>
            <div>
                <ShadowBoxComponent className={classes.shadowBox}>
                    {sizeView !== 'XS' && <>
                        {!load && <img src="/asset/images/preload_image.png" className={classes.cardImagePreload} alt={'Image formation'}/>}
                        <img src={props.image && props.image} className={classes.cardImage} alt={'Image formation'} onLoad={() => setLoad(true)}/>
                    </>}

                    <div style={{paddingLeft: sizeView === 'XS' ? 10 : 190, paddingTop: sizeView === 'XS' ? 15 : 0, textAlign: "left"}}>
                        <p style={{fontWeight: 700, fontSize: 14}}>{props.title}</p>
                        <div>
                            <ul style={{paddingLeft: 30}}>
                               
                                {/* Livret Formation SFP */}
                                {
                                    props.pathFormationSyntheses &&
                                    <li style={{listStyleType: 'square', color: theme.colorPrimary, fontSize: 14, lineHeight: '20px'}}>
                                        <MediaLinkComponent
                                            style={{color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                                            path={props.pathFormationSyntheses}
                                            type={1}
                                        >
                                            Livret de formation
                                        </MediaLinkComponent>
                                    </li>
                                }

                                {/* Diplome et Attestation de fin de formation (type finance :  DPC et FIFPL conventionné) (organisme : Tous) */}
                                {
                                    (props.financeType !== '2' && props.financeType !== '6' && props.isFinish) && 
                                        <>
                                            <li style={{listStyleType: 'square', color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}  onClick={() => {
                                                const url = Api.route({
                                                    route: 'learner_download_participation_certificate',
                                                    data: {registration: props.registration && props.registration}
                                                });
                                                window.open(url, '_blank');
                                            }}>
                                                {props.isDiplomaFormation ? 'Attestation de fin de formation' : 'Attestation de participation'}
                                            </li>
                                            <li style={{listStyleType: 'square', color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}  onClick={() => {
                                                const url = Api.route({
                                                    route: 'learner_document_diplome',
                                                    data: {registration: props.registration && props.registration}
                                                });
                                                window.open(url, '_blank');
                                            }}>
                                                Diplôme
                                            </li>
                                        </>
                                }

                                {/* Attestation de fin de formation (type finance : Personnel) (organisme : Tous sauf Timelia) */}
                                {
                                    (props.financeType === '6' && props.organism !== 4 && props.isFinish) &&
                                        <li style={{listStyleType: 'square', color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}  onClick={() => {
                                            const url = Api.route({
                                                route: 'learner_document_download_partitipation_certificate_personal',
                                                data: {registration: props.registration && props.registration}
                                            });
                                            window.open(url, '_blank');
                                        }}>
                                            Attestation de fin de formation
                                        </li>
                                }

                                {/* Attestation de fin de formation (type finance : Personnel et Gratuite) (organisme : Timelia) */}
                                {
                                    ((props.financeType === '6' || props.financeType === '2') && props.organism === 4 && props.isFinish) &&
                                        <li style={{listStyleType: 'square', color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}  onClick={() => {
                                            const url = Api.route({
                                                route: 'learner_document_download_acquisition_certificate_fifpl_tml',
                                                data: {registration: props.registration && props.registration}
                                            });
                                            window.open(url, '_blank');
                                        }}>
                                            Attestation d’acquisition de connaissances
                                        </li>
                                }

                                {/* Convention de formation (type finance : Personnel) (organisme : Timelia) */}
                                {
                                    (props.financeType === '6' && props.organism === 4) &&
                                    <li style={{listStyleType: 'square', color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}  onClick={() => {
                                        const url = Api.route({
                                            route: 'learner_document_download_convention_formation_tml',
                                            data: {registration: props.registration && props.registration}
                                        });
                                        window.open(url, '_blank');
                                    }}>
                                        Convention de formation
                                    </li>
                                }

                                {/* Attestation Fifpl non conventionné (type finance : Personnel) ( organisme : Timelia) */}
                                {
                                    (props.financeType === '6' && props.isFifpl && props.isFinish) && 
                                        <li style={{listStyleType: 'square', color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}  onClick={() => {
                                            const url = Api.route({
                                                route: 'learner_document_download_participation_certificate_fifpl',
                                                data: {registration: props.registration && props.registration}
                                            });
                                            window.open(url, '_blank');
                                        }}>
                                            Attestation de présence et de règlement
                                        </li>
                                }
                            </ul>
                        </div>
                    </div>

                    <LinearProgressMUI className={classes.linearProgress} hidden={load}/>
                </ShadowBoxComponent>
            </div>
        </Fade>
    );
}

const useStyles = (theme) => makeStyles(({
    shadowBox: {
        padding: 0,
        paddingRight: 10,
        height: 180,
        textAlign: 'center',
        transition: 'all 700ms',
        opacity: 1,
        overflow: 'hidden',
    },
    cardImagePreload: {
        height: 180,
        width: '100%',
        maxWidth: 180,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    cardImage: {
        height: 180,
        width: '100%',
        maxWidth: 180,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0
    },
    cardText: {
        color: '#228B9F',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    cardHour: {
        position: 'absolute',
        fontWeight: 700,
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF',
        padding: '5px 5px',
        borderRadius: '0 0 5px 0',
        margin: 0,
        fontSize: 12,
        top: 0,
        left: 0,
        zIndex: 1
    },
    cardHourIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    cardState: {
        position: 'absolute',
        fontWeight: 700,
        color: '#FFFFFF',
        padding: '3px 5px',
        borderRadius: 20,
        margin: 5,
        fontSize: 12,
        bottom: 0,
        right: 0
    },
    cardStateIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    }
}));

export default CardFormation;
